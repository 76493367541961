<template>
    <div class="wrapper">
        <iTable
            :table-data="dataList"
            :columns="columns"
            :pagination="pagination"
            tabletype="4"
            ref="iTable"
            :other-height="230"
            :border="false"
            @handleTableChange="handleTableChange"
            @openDetail="openDetail"
            @msgRead="msgRead"
        ></iTable>
    </div>
</template>

<script>
import iTable from './components/iTable';
import { eventBus } from '@/common/eventBus.ts';
export default {
    components: {
        iTable,
    },
    props: {
        msgType: {
            type: [Number],
        },
    },
    data() {
        return {
            terminalTypeEnum: 1, // 所有=0, pc=1, app=2, 司机小程序=4
            currentIndex: 1,
            pagination: { size: 10 },
            dataList: [],
            columns: [
                {
                    prop: 'title',
                    label: '标题',
                    format: row => {
                        return '<a href="javascript:;">' + row.title + '</a>';
                    },
                    method: this.openDetail,
                },
                {
                    prop: 'content',
                    label: '更新内容',
                    width: 700,
                },
                {
                    prop: 'take_effect_time',
                    label: '更新时间',
                },
            ],
        };
    },
    created() {
        this.getMsgList();
    },
    watch: {},
    computed: {},
    methods: {
        // 已读消息
        msgRead(id) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/message_read/' + id)
                .then(() => {
                    // this.$message.success(res);
                    this.getMsgList();
                    eventBus.$emit('updateSysUnread');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        handleTableChange() {
            if (Math.ceil(this.pagination.total / this.pagination.size) !== this.currentIndex) {
                this.currentIndex++;
                this.getMsgList();
            }
        },
        getMsgList() {
            const qs = require('qs');
            const param = {
                // typeEnum: this.msgType,
                terminalTypeEnum: this.terminalTypeEnum,
                pageindex: this.currentIndex,
                pagesize: 10,
            };
            this.$axios
                .get('/interfaceApi/message/system_update/page_list?' + qs.stringify(param))
                .then(res => {
                    this.dataList.push(...res.rows);
                    this.pagination.total = res.total;
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 查看信息详情
        openDetail(row) {
            this.$toast({
                title: false,
                type: 'eject',
                width: '8rem',
                height: '6.4rem',
                t_url: 'messageCenter/pages/page/components/dialogue/sysUpdate',
                extr: {
                    msgTime: row.take_effect_time,
                    msgTxt: row.content,
                },
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.wrapper
    padding: 0.1rem;
    padding-left 0.3rem;
    height: 100%;
    a,/deep/ a
        color #1577D2
        &:hover
            text-decoration underline;
    table,/deep/ table
        th
            border-bottom: 2px dashed #999;

</style>
